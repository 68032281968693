
import CyanPageWrapper, {
  store,
  defineComponent,
} from "@/components/CyanPageWrapper.vue";
import PartyEntry from "@/components/PartyEntry.vue";
import { territorios } from "@/modules/cyanRegions";
import ud from "@/modules/cyanUserData.ts";

import {
  IonList,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonGrid,
  IonRow,
  IonButton,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonInput,
  alertController,
} from "@ionic/vue";
import { informationCircle } from "ionicons/icons";
import unreact from "@/modules/unreact";
import cyanRequest from "@/modules/cyanRequest";

export default defineComponent({
  name: "DirectorioStreamlined",
  components: {
    IonList,
    IonButton,
    IonPage,
    IonInput,
    IonSegment,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    IonSegmentButton,
    IonGrid,
    IonRow,
    IonCol,
    CyanPageWrapper,
    PartyEntry,
  },
  setup() {
    return { informationCircle };
  },
  data() {
    return {
      grupoSeleccionado: "",
      duiCargoRaw: "",
      telCargoRaw: "",
      idCargo: 0,
    };
  },

  watch: {
    duiCargoRaw: function () {
      if (this.duiCargoRaw != this.duiCargo) {
        this.duiCargoRaw = this.duiCargo;
        const el = document.getElementById("duiCargo") as any;

        if (el) {
          el.value = this.duiCargo; // bug en ion-input
          if (
            el.children &&
            el.children[0] &&
            el.children[0].tagName == "INPUT"
          )
            el.children[0].value = this.duiCargo;
        }
      }
    },
    telCargoRaw: function () {
      if (this.telCargoRaw != this.telCargo) {
        this.telCargoRaw = this.telCargo;
        const el = document.getElementById("telCargo") as any;

        if (el) {
          el.value = this.telCargo; // bug en ion-input
          if (
            el.children &&
            el.children[0] &&
            el.children[0].tagName == "INPUT"
          )
            el.children[0].value = this.telCargo;
        }
      }
    },

    grupoSeleccionado() {
      store.commit("storeTabDirectorio", this.grupoSeleccionado);
    },
    datos() {
      const _t = this as any;
      if (!_t.datos) return;

      this.checkTabAlmacenada();
    },
  },
  computed: {
    duiCargo(): string {
      const f = this.duiCargoRaw.replace(/[^0-9]/g, "");
      if (f.length < 9) return f;
      return f.substr(0, 8) + "-" + f.substr(8, 1);
    },
    telCargo(): string {
      let f = this.telCargoRaw.replace(/[^0-9]/g, "");
      if (f.length > 0 && (f[0] != '6' && f[0] != '7')) f = '';
      if (f.length < 4) return f;
      return f.substr(0, 4) + "-" + f.substr(4, 4);
    },
    esAsistencia() {
      return (this as any).$route.path.split("/")[1] === "jrslasist";
    },
    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in territorios)) return "";
      return territorios[n].nombre + ", " + territorios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },
    conGrupoSeleccionado(): any {
      return !!(
        this.datos &&
        this.datos.groups &&
        this.datos.groups[this.grupoSeleccionado]
      );
    },
    datosWrapped(): any {
      return store.getters.directorioStreamlined;
    },
    datos(): any {
      const _t = this as any;

      if (!_t.datosWrapped || !_t.datosWrapped.ok || !_t.datosWrapped.value)
        return false;

      // Construir datos filtrados

      const dw = _t.datosWrapped.value;

      // Para el directorio general, eliminar asistenciaMarcable de datosCargos, el resto se queda igual

      if (!_t.esAsistencia) return dw;

      // Es asistencia. Aquí hay que trabajar de abajo arriba.

      const df = {
        groups: {},
        groupsOrder: [] as string[],
        datosCargos: {},
      };

      // Primero, trasladamos a datosCargos únicamente aquellos que tengan asistenciaMarcable.

      const dca = df.datosCargos as any;

      for (const e in dw.datosCargos) {
        if (dw.datosCargos[e].asistenciaMarcable) dca[e] = dw.datosCargos[e];
      }
      // Para cada grupo en los datos originales...

      for (const g of dw.groupsOrder) {
        const newCargos = {};
        const newCargosOrder = [];
        // Para cada sub-epígrafe...
        for (const c of dw.groups[g].cargosOrder) {
          const newList = [];
          for (const idCargo of dw.groups[g].cargos[c]) {
            if (typeof dca[idCargo] === "undefined") continue;
            newList.push(idCargo);
          }

          if (newList.length == 0) continue;

          newCargosOrder.push(c);
          (newCargos as any)[c] = newList;
        }

        if (newCargosOrder.length == 0) continue;

        (df as any).groups[g] = {
          cargos: newCargos,
          cargosOrder: newCargosOrder,
          tipo: dw.groups[g].tipo,
          esJunta: dw.groups[g].esJunta,
          junta: dw.groups[g].junta ?? null,
        };
        df.groupsOrder.push(g);
      }
      return df;
    },

    datosGrupoSeleccionado(): any {
      if (
        !this.conDatos ||
        !this.datos.groups ||
        !this.datos.groups[this.grupoSeleccionado]
      )
        return {};
      return this.datos.groups[this.grupoSeleccionado];
    },

    conDatos() {
      const d = this.datos as any;
      return d && d.groupsOrder && d.groupsOrder.length;
    },
    cargosGrupoSeleccionado() {
      if (!(this as any).conDatos) return {};
      return ud(
        (this as any).datosGrupoSeleccionado.esJunta
          ? "cargosJunta"
          : "cargosCentro",
        {}
      );
    },
  },
  mounted() {
    this.checkTabAlmacenada();
  },
  methods: {
    async altaCargo() {
      const ntc = this.cargosGrupoSeleccionado[this.idCargo];
      const problemas = [];

      if (!ntc) problemas.push("Debe especificar un cargo.");

      if (this.duiCargo.length != 10)
        problemas.push("Debe especificar una cédula válida.");

      if (this.telCargo.length != 0 && this.telCargo.length != 9)
        problemas.push('El teléfono no es válido.')

      if (problemas.length) {
        alertController
          .create({
            message: problemas.join(" "),
            buttons: ["OK"],
          })
          .then((alert) => alert.present());

        return;
      }

      const _t = this as any;

      if (
        !(await new Promise(function (resolve) {
          alertController
            .create({
              message:
                "¿Confirma que desea dar de alta la cédula " +
                _t.duiCargo +
                " como " +
                ntc +
                " para " +
                (_t.datosGrupoSeleccionado.esJunta
                  ? "la JRV " + _t.datosGrupoSeleccionado.junta
                  : "el centro") +
                "?",
              buttons: [
                {
                  text: "NO",
                  role: "cancel",
                  handler: function () {
                    resolve(false);
                  },
                },
                {
                  text: "SÍ",
                  handler: function () {
                    resolve(true);
                  },
                },
              ],
            })
            .then((alert) => alert.present());
        }))
      ) {
        return;
      }

      cyanRequest("jrsl/addCargo", {
        isPost: true,
        isModal: true,
        needsAuth: true,
        retries: 3,
        params: {
          tipo: _t.datosGrupoSeleccionado.esJunta ? "junta" : "centro",
          junta: _t.datosGrupoSeleccionado.junta ?? null,
          tipoCargo: _t.idCargo,
          dui: _t.duiCargo,
          telefono: _t.telCargo
        },
      }).then(function (d) {
        if (d.ok) {
          store.commit("storeDirectorioStreamlined", d);

          _t.duiCargoRaw = "";

          alertController
            .create({
              message: "Cargo creado con éxito.",
              buttons: ["OK"],
            })
            .then((alert) => alert.present());
        }
      });
    },

    checkTabAlmacenada() {
      const _t = this as any;
      const tabAlmacenada = unreact(store.state.currentTabDirectorio??'');

      if (!_t.datos || !_t.datos.groups || !_t.datos.groupsOrder) return;

      if (
        tabAlmacenada == _t.grupoSeleccionado &&
        _t.datos.groups[_t.grupoSeleccionado]
      )
        return;

      if (_t.datos.groups[tabAlmacenada]) {
        _t.grupoSeleccionado = tabAlmacenada;
      } else {
        _t.grupoSeleccionado = _t.datos.groupsOrder[0];
      }
    },
  },
  props: {},
});
